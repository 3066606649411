<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <h1>Vuex</h1>
      </v-col>
      <v-col cols="12">
        <div class="d-flex">
          <v-btn
            class="mr-3"
            color="primary"
            @click="asce('amount')"
          >
            Low to High
          </v-btn>
          <v-btn
            class="mr-3"
            color="primary"
            outlined
            @click="desc('amount')"
          >
            High to Low
          </v-btn>
        </div>
      </v-col>
      <v-col
        v-for="(product, index) in getProducts"
        :key="index"
        cols="12"
        xl="2"
        lg="3"
        class="mb-10"
      >
        <card-cart
          :content-img="product.img"
          :content-text="product.title"
          :amount="product.amount"
          class="m-5"
        />
      </v-col>
      <v-col
        cols="12"
        class="mb-5"
      >
        <h1 class="mb-8">
          Combox
        </h1>

        <v-select
          v-model="select"
          :items="countries"
          label="Select"
          item-text="name"
        >
          <template v-slot:item="slotProps">
            <v-icon :class="['mr-2', 'em' ]">
              {{ slotProps.item.flag }}
            </v-icon>
            {{ slotProps.item.name }}
          </template>
        </v-select>
      </v-col>
      <v-col
        cols="12"
        class="mb-5"
      >
        <v-btn
          color="primary"
          @click="checkList"
        >
          Click
        </v-btn>
        <div
          v-for="(n,index) in customItems"
          :key="index"
        >
          <div class="d-flex">
            <h5 class="me-3">
              {{ n.id }}
            </h5>
            <h5>{{ n.title }}</h5>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
  import { mapGetters, mapMutations } from 'vuex'
  import CardCart from '@/components/cartCard/CardCart'
  export default {
    components: {
      listitems: [],
      CardCart,
    },
    computed: {
      ...mapGetters(['getProducts']),
      ...mapMutations(['DESC_ORDER']),
      customItems () {
        return this.newList = this.listitems.slice(0, 5).map(n => n)
      },
    },
    data () {
      return {
        state: 0,
        fullProducts: '',
        select: '',
        newList: [],
        countries: [
          {
            name: 'Andorra',
            flag: 'mdi-play',
          },
          {
            name: 'Arab Emirates',
            flag: 'mdi-play',
          },
          {
            name: 'Afghanistan',
            flag: 'em-flag-af',
          },
          {
            name: 'Antigua & Barbuda',
            flag: 'em-flag-ag',
          },
          {
            name: 'Albania',
            flag: 'em-flag-al',
          },
          {
            name: 'Anguilla',
            flag: 'em-flag-ai',
          },
        ],
        listitems: [
          {
            id: 1,
            img: require('@/assets/images/products/flash-1.png'),
            title: 'Sneakers',
            category: 'Sneakers',
            amount: 300,
            rating: 3,
            qty: 0,
          },
          {
            id: 2,
            img: require('@/assets/images/products/flash-2.png'),
            title: 'Watch',
            category: 'Watch',
            amount: 200,
            rating: 5,
            qty: 0,
          },
          {
            id: 3,
            img: require('@/assets/images/products/flash-3.png'),
            title: 'Mobile',
            category: 'Mobile',
            amount: 100,
            rating: 4,
            qty: 0,
          },
          {
            id: 4,
            img: require('@/assets/images/products/flash-4.png'),
            title: 'Zapple Watch',
            category: 'Watch',
            amount: 20,
            rating: 2,
            qty: 0,
          },
          {
            id: 5,
            img: require('@/assets/images/products/flash-1.png'),
            title: 'Hike Sneakers',
            category: 'Sneakers',
            amount: 700,
            rating: 5,
            qty: 0,
          },
          {
            id: 6,
            img: require('@/assets/images/products/flash-2.png'),
            title: 'Watch',
            category: 'Watch',
            amount: 250,
            rating: 4.5,
            qty: 0,
          },
          {
            id: 7,
            img: require('@/assets/images/products/flash-3.png'),
            title: 'Mobile',
            category: 'Mobile',
            amount: 150,
            rating: 5,
            qty: 0,
          },
          {
            id: 8,
            img: require('@/assets/images/products/flash-4.png'),
            title: 'Zapple Watch',
            category: 'watch',
            amount: 950,
            rating: 4,
            qty: 0,
          },
          {
            id: 9,
            img: require('@/assets/images/products/Electronics/29.AppleEarphones.png'),
            title: 'Kony Earbuds',
            category: 'Accessories',
            amount: 150,
            rating: 3,
            qty: 0,
          },
          {
            id: 10,
            img: require('@/assets/images/products/Electronics/30.Nokiaandroidone.png'),
            title: 'Mobile',
            category: 'Mobile',
            amount: 66,
            rating: 5,
            qty: 0,
          },
          {
            id: 11,
            img: require('@/assets/images/products/Electronics/4.LumixDSLR.png'),
            title: 'Camera',
            category: 'Electronics',
            amount: 175,
            rating: 4,
            qty: 0,
          },
          {
            id: 12,
            img: require('@/assets/images/products/Electronics/2.COSOR1.png'),
            title: 'Coffee Maker',
            category: 'Electronics',
            amount: 275,
            rating: 5,
            qty: 0,
          },
          {
            id: 13,
            img: require('@/assets/images/products/Electronics/20.TascuigoAriadryLight.png'),
            title: 'Mini Fridge',
            category: 'Electronics',
            amount: 375,
            rating: 4.5,
            qty: 0,
          },
          {
            id: 14,
            img: require('@/assets/images/products/Electronics/31.Symphonlights.png'),
            title: 'Mobile',
            category: 'mobile',
            amount: 655,
            rating: 5,
            qty: 0,
          },
          {
            id: 15,
            img: require('@/assets/images/products/flash-3.png'),
            title: 'Smart Phone',
            category: 'Mobile',
            amount: 655,
            rating: 5,
            qty: 0,
          },
          {
            id: 16,
            img: require('@/assets/images/products/bgmobile1.png'),
            title: 'Kamsung Phone',
            category: 'Mobile',
            amount: 865,
            rating: 1,
            qty: 0,
          },
        ],
      }
    },
    methods: {
      checkList () {
        console.log(this.newList)
      },
      desc (prop) {
        this.fullProducts = this.getProducts

        this.getProducts = this.fullProducts.sort((a, b) => a[prop] > b[prop] ? -1 : 1)
      },
      asce (prop) {
        this.fullProducts = this.getProducts

        this.getProducts = this.fullProducts.sort((a, b) => a[prop] < b[prop] ? -1 : 1)
      },
    },

  }
</script>
